import React, { useEffect, useState } from "react";
import { navigate } from 'gatsby';
import { useLocation } from "@reach/router";

import Layout from "../../../components/Layout";
import { Col, Container, Row } from "react-bootstrap";
import { handleRedirectCallback, persistLoggedInUser } from '../../../services/auth';
import axios from "axios";


const Callback = (props) => {

  const [text, setText] = useState('Loading, please wait...');
  const location = useLocation();
  const baseUrl = process.env.GATSBY_API_URL || 'http://localhost:1337';

  useEffect(() => {
    const accessToken = handleRedirectCallback(location.search);
    // console.log("AccessToken:", accessToken);
    // Call backend to retrieve JWT using the access token
    //fetch(`${baseUrl}/auth/microsoft/callback/?access_token=${accessToken}`)

    const apiUrl = `${baseUrl}/auth/microsoft/callback/?access_token=${accessToken}`;
    
    //const config = {};

    //axios.get(apiUrl, config)
    fetch(apiUrl)
      .then(res => {
        if (res.status !== 200) {
          throw new Error(`Couldn't log in user. Status: ${res.status}`);
        }
        return res;
      })
      .then(res => res.json())
      .then(res => {
        // Successfully logged in, save the jwt for auth requests to Strapi
        persistLoggedInUser(res);
        setText('You have successfully logged in and will be redirected in a few seconds...');
                
        setTimeout(() => navigate('/'), 1500);
      })
      .catch(err => {
        console.log(err);
        setText('An error has occurred while logging you in. Please try again later...');
      });

  }, [location.search]);

  return (
    <Layout>
      <Container>
        <Row>
          <Col>
            {/* {props.location.search}<br /> */}
            Loading, please wait...
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default Callback;
